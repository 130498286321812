export class Indicador {
    key: string;
    alteraSenha: boolean;
    ativo: boolean;
    avatar: string;
    cpf: string;
    nome: string;
    email: string;
    gestorId: string;
    endereco: {
        logradouro: string,
        cidade,
    };
    loja: {
        nome: '',
    };
    password: string;
}


export class Cliente {
    cpf: string;
    nome: string;
    telefone: string;
    modelo: string;
}