import { Injectable, NgZone } from "@angular/core";
import { auth } from "firebase/app";
import { User } from "./user";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { LoadingService } from "../loading.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  userData: any;

  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private loading: LoadingService
  ) {
    this.ngFireAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem("user"));
      } else {
        localStorage.setItem("user", null);
        JSON.parse(localStorage.getItem("user"));
      }
    });
  }

  // Login in with email/password
  signIn(email: string, password: string) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }

  // Register user with email/password
  RegisterUser(email: string, password: string) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  // // Email verification when new user register
  // SendVerificationMail() {
  //     return this.ngFireAuth.currentUser.sendEmailVerification()
  //         .then(() => {
  //             this.router.navigate(['verify-email']);
  //         })
  // }

  // Recover password
  async passwordRecover(passwordResetEmail: string) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail);
    // .then(() => {
    //     // window.alert('Password reset email has been sent, please check your inbox.');
    // }).catch((error) => {
    //     window.alert(error);
    // });
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null ? true : false;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user.emailVerified !== false ? true : false;
  }

  // Sign in with Gmail
  async googleAuth() {
    return await this.authLogin(new auth.GoogleAuthProvider());
  }

  // Auth providers
  async authLogin(provider: any) {
    return this.ngFireAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(["dashboard"]);
        });
        this.setUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Store user in localStorage
  setUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign-out
  async signOut() {
    await this.loading.presentLoading("Desconectando...");
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("indicador");
      this.router.navigate(["login"]);
      this.loading.dimissLoading();
    });
  }
}
