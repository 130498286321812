import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loadingProgress: any = undefined;

  constructor(private loadingController: LoadingController) { }

  public async presentLoading(message: string) {
    this.loadingProgress = await this.loadingController.create({
      message,
    });
    await this.loadingProgress.present();
  }

  public dimissLoading() {
    this.loadingProgress.dismiss();
  }
}
