import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { Indicador } from "./user";
import { AuthenticationService } from "../services/shared/authentication-service";
import { IndicadorService } from "./indicador/indicador-service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private indicadorService: IndicadorService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    return true;
  }

  async signIn(indicador: Indicador) {
    return new Promise((resolve, reject) => {
      this.authService
        .signIn(indicador.email, indicador.password)
        .then(async (res) => {
          const user = await this.indicadorService.getByEmail(indicador.email);
          if (!user) {
            reject("Falha ao recuperar o usuário");
            return;
          }
          localStorage.setItem("indicador", JSON.stringify(user));
          resolve(user);
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            reject("Usuário não encontrado, verique o email informado");
          } else if (error.code === "auth/wrong-password") {
            reject("Senha inválida, verifique");
          } else if (error.code === "auth/too-many-requests") {
            reject(
              "Muitas tentativas de login sem sucesso. Tente novamente mais tarde"
            );
          } else {
            reject(error.message);
          }
        });
    });
  }

  async passwordRecover(email: string) {
    return new Promise((resolve, reject) => {
      this.authService
        .passwordRecover(email)
        .then((res) => {
          resolve("Instruções de recuperação enviadas para o seu email");
        })
        .catch((err) => {
          if (err.code === "auth/user-not-found") {
            reject(
              "Email não corresponde a nenhuma conta cadastrada em nossa plataforma"
            );
          } else {
            reject(
              "Houve um problema ao enviar as instruções de recuperação. Verifique se o seu email está correto"
            );
          }
        });
    });
  }

  isLogged() {
    return this.authService.isLoggedIn;
  }
}
