import { Injectable, NgZone } from '@angular/core';
import { Indicador } from '../user';
import * as firebase from 'firebase/app';
import 'firebase/firestore';


@Injectable({
    providedIn: 'root'
})

export class IndicadorService {

    constructor() { }

    async getByEmail(email: string) {
        const data = await firebase.firestore().collection('indicadores')
            .where('email', '==', email)
            .get();
        if (data.empty) {
            return undefined;
        }
        const indicador = new Indicador();
        indicador.key = data.docs[0].id;
        indicador.ativo = data.docs[0].data().ativo;
        indicador.avatar = data.docs[0].data().avatar;
        indicador.cpf = data.docs[0].data().cpf;
        indicador.email = data.docs[0].data().email;
        indicador.endereco = data.docs[0].data().endereco;
        indicador.loja = data.docs[0].data().loja;
        indicador.nome = data.docs[0].data().nome;
        indicador.gestorId = data.docs[0].data().gestorId;
        return indicador;
    }
    updatePhotoUrl() { }
}
