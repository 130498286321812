import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/shared/authentication-service';
import { Router } from '@angular/router';
import { SingleService } from './services/single.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  versao = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthenticationService,
    private router: Router,
    private single: SingleService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.versao = this.single.VERSAO;
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  // ** MENU METHODS

  profile() {
    this.router.navigate(['profile']);
  }

  report() {
    this.router.navigate(['report']);
  }

  aboutUS() {
    this.router.navigate(['about']);
  }

  privacy() {
    this.router.navigate(['privacy']);
  }

  logOut() {
    this.authService.signOut();
  }
}
